html {
  font-size: 14px;
  box-sizing: border-box;
}
html,
body,
#root {
  margin: 0;
  padding: 0;
  height: 100%;
}
body {
  min-width: 900px;
  color: #8694b1;
  background-color: #232d41;
}
body,
button,
input,
optgroup,
select,
textarea {
  font-family: 'proxima_novalight', sans-serif;
  font-size: 14px;
  letter-spacing: 0.6px;
}
button,
html [type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: initial;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* this is for next phase */
:root {
  --ctl-admin-bg-color: #161d2c;
  --ctl-admin-bg-color-dark: #030a18;
  --ctl-admin-color: #8993ae;
  --ctl-admin-color-active: #fff;
}
